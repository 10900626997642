import { RpEntity } from '@inway/webauthn-client';
import { useEffect, useState } from 'react';
import { AssertionArgs } from '../assertion';
import useRegistry from '../hooks/useRegistry';
import { RegistrationArgs } from '../registration';
import { RegistryEntry } from '../types';
import Registry from './Registry';

export interface FormProps {
  onRegistration: (
    args: RegistrationArgs,
    registry: RegistryEntry[]
  ) => Promise<any>;
  onAssertion: (
    options: AssertionArgs,
    username: string,
    registry: RegistryEntry[]
  ) => Promise<any>;
}

export default function Form({ onAssertion, onRegistration }: FormProps) {
  const [username, setUsername] = useState('user@example.com');
  const [password, setPassword] = useState('');
  const [attestation, setAttestation] = useState('none');
  const [authenticatorAttachment, setAuthenticatorAttachment] = useState('');
  const [residentKey, setResidentKey] = useState('discouraged');
  const [requireResidentKey, setRequireResidentKey] = useState('false');
  const [userVerification, setUserVerification] = useState('discouraged');
  const [rpInfo, setRpInfo] = useState<RpEntity | null>(null);

  const [registry, updateRegistry] = useRegistry();
  const [lastCredential, setLastCredential] = useState<string>();

  const [registering, setRegistering] = useState(false);
  const [asserting, setAsserting] = useState(false);

  useEffect(() => {
    let isMounted = true;

    fetch('/api/rp', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) throw new Error(`Invalid response to rp request`);

        return response.json();
      })
      .then((rp) => {
        if (isMounted) setRpInfo(rp);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!registering) return;

    let isMounted = true;

    onRegistration(
      {
        username,
        password,
        attestation,
        authenticatorSelection: {
          authenticatorAttachment: authenticatorAttachment || undefined,
          residentKey,
          requireResidentKey: requireResidentKey === 'true' ? true : false,
          userVerification,
        },
      },
      registry
    )
      .then(({ result, options }) => {
        if (isMounted)
          updateRegistry({
            type: 'add',
            entry: {
              username: options.username,
              password: options.password,
              id: result.credential.id,
              type: result.credential.type,
              transports: result.credential.response.transports,
              credentialPublicKey:
                result.attestation.authData.attestedCredentialData
                  .credentialPublicKey,
              storedSignCount: result.attestation.authData.signCount,
              attestationFormat: result.attestation.fmt,
              attestationType: result.attestation.attestationType,
            },
          });

        return { attestation: result, options };
      })
      .catch((err) => console.error(err))
      .finally(() => {
        if (isMounted) setRegistering(false);
      });

    return () => {
      isMounted = false;
    };
  }, [onRegistration, registering]);

  useEffect(() => {
    if (!asserting) return;

    let isMounted = true;

    onAssertion(
      {
        userVerification,
      },
      username,
      registry
    )
      .then(({ result, options, credentialSource }) => {
        (
          Object.entries(credentialSource) as [
            string,
            { storedSignCount: number },
          ][]
        ).forEach(([credentialId, { storedSignCount }]) => {
          const entry = registry.find((entry) => entry.id === credentialId);
          if (entry) entry.storedSignCount = storedSignCount;
        });

        if (isMounted) {
          // setRegistry([...registry]);
          setLastCredential(result.credential.id);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        if (isMounted) setAsserting(false);
      });

    return () => {
      isMounted = false;
    };
  }, [onAssertion, asserting]);

  useEffect(() => {
    if (!lastCredential) return;

    let isMounted = true;

    let timer = setTimeout(() => {
      if (isMounted) setLastCredential(undefined);
    }, 2500);

    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [lastCredential]);

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              autoComplete="new-username"
              id="username"
              placeholder="user@example.com"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor="username">
              Username
              <div className="ms-2 badge rounded-pill bg-primary">Register</div>
              <div className="ms-2 badge rounded-pill bg-success">Login</div>
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3">
            <input
              type="password"
              autoComplete="new-password"
              className="form-control"
              id="password"
              placeholder="user@example.com"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password">
              Password
              <div className="ms-2 badge rounded-pill bg-primary">Register</div>
            </label>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-12">
          <div className="form-floating">
            <select
              className="form-select"
              id="attestation"
              value={attestation}
              onChange={(e) => setAttestation(e.target.value)}
            >
              <option value="none">None</option>
              <option value="indirect">Indirect</option>
              <option value="direct">Direct</option>
              <option value="enterprise">Enterprise</option>
            </select>
            <label htmlFor="attestation">
              Attestation Type
              <div className="ms-2 badge rounded-pill bg-primary">Register</div>
            </label>
          </div>
        </div>

        <div className="col-md-12">Authenticator selection criteria</div>

        <div className="col-lg-6 col-md-12">
          <div className="form-floating">
            <select
              className="form-select"
              id="authenticatorAttachment"
              value={authenticatorAttachment}
              onChange={(e) => setAuthenticatorAttachment(e.target.value)}
            >
              <option value="">Unspecified</option>
              <option value="cross-platform">Cross platform</option>
              <option value="platform">Platform (TPM)</option>
            </select>
            <label htmlFor="authenticatorAttachment">
              Authenticator Attachment
              <div className="ms-2 small badge rounded-pill bg-primary">
                Register
              </div>
            </label>
          </div>
        </div>

        <div className="col-lg-6 col-md-12">
          <div className="form-floating">
            <select
              className="form-select"
              id="residentKey"
              value={residentKey}
              onChange={(e) => setResidentKey(e.target.value)}
            >
              <option value="discouraged">Discouraged</option>
              <option value="preferred">Preferred</option>
              <option value="required">Required</option>
            </select>
            <label htmlFor="residentKey">
              Resident Key
              <div className="ms-2 badge rounded-pill bg-primary">Register</div>
            </label>
          </div>
        </div>

        <div className="col-lg-6 col-md-12">
          <div className="form-floating">
            <select
              className="form-select"
              id="requireResidentKey"
              value={requireResidentKey}
              onChange={(e) => setRequireResidentKey(e.target.value)}
            >
              <option value="false">Not Required</option>
              <option value="true">Required</option>
            </select>
            <label htmlFor="requireResidentKey">
              Register with Resident Key
              <div className="ms-2 badge rounded-pill bg-primary">Register</div>
            </label>
          </div>
        </div>

        <div className="col-lg-6 col-md-12">
          <div className="form-floating">
            <select
              className="form-select"
              id="userVerification"
              value={userVerification}
              onChange={(e) => setUserVerification(e.target.value)}
            >
              <option value="discouraged">Discouraged</option>
              <option value="preferred">Preferred</option>
              <option value="required">Required</option>
            </select>
            <label htmlFor="userVerification">
              User Verification
              <div className="ms-2 badge rounded-pill bg-primary">Register</div>
              <div className="ms-2 badge rounded-pill bg-success">Login</div>
            </label>
          </div>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-start align-items-center py-3">
        <button
          id="register"
          className={`btn btn-lg btn-primary ${
            rpInfo != null ? '' : 'me-auto'
          }`}
          disabled={registering || asserting}
          onClick={() => {
            setRegistering(true);
          }}
        >
          {registering && (
            <div
              className="spinner-border spinner-border-sm me-2"
              role="status"
            ></div>
          )}
          Register
        </button>
        {rpInfo && (
          <pre className="ms-2 me-auto mb-0 -small -text-muted">
            <code>rpId: {rpInfo.id}</code>
          </pre>
        )}
        {registry.length === 0 && (
          <span className="me-2 small text-muted">
            Please register some credentials to test login
          </span>
        )}
        {lastCredential && (
          <span className="me-2 small fw-bold text-success">
            Logged in with: {lastCredential.substring(0, 10)}...
          </span>
        )}
        <button
          id="login"
          className="btn btn-lg btn-success"
          disabled={registry.length === 0 || registering || asserting}
          onClick={() => {
            setAsserting(true);
          }}
        >
          {asserting && (
            <div
              className="spinner-border spinner-border-sm me-2"
              role="status"
            ></div>
          )}
          Login
        </button>
      </div>

      <Registry highlight={lastCredential} />
    </>
  );
}
