import React from 'react';
import useRegistry from '../hooks/useRegistry';
import { RegistryEntry } from '../types';

export interface RegistryProps {
  highlight?: string;
}

export default function Registry({ highlight }: RegistryProps) {
  const [registry, updateRegistry] = useRegistry();

  const users: { [key: string]: { password: string; keys: RegistryEntry[] } } =
    registry.reduce((u, entry) => {
      if (!(entry.username in u)) {
        u[entry.username] = {
          password: entry.password,
          keys: [],
        };
      }
      u[entry.username].keys.push(entry);

      return u;
    }, {} as any);

  return (
    <div className="table-responsive">
      <table id="registry" className="table table-sm">
        <thead>
          <tr>
            <th scope="col" colSpan={2}>
              credentialId / attestation
            </th>
            <th scope="col">credentialPublicKey</th>
            <th scope="col">transports</th>
            <th scope="col">storedSignCount</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(users)
            .sort()
            .map((username, idx) => (
              <React.Fragment key={[username, idx].join('-')}>
                <tr className="table-primary">
                  <td colSpan={5} className="">
                    <span className="fw-bold me-2">{username}</span>
                    <span className="small me-2">
                      (password: {users[username].password})
                    </span>
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();

                        updateRegistry({
                          type: 'removeUser',
                          username,
                        });
                      }}
                    >
                      [x]
                    </a>
                  </td>
                </tr>
                {users[username].keys.map((entry) => (
                  <tr
                    key={entry.id}
                    className={highlight === entry.id ? 'table-success' : ''}
                  >
                    <td>
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();

                          updateRegistry({
                            type: 'removeKey',
                            entry,
                          });
                        }}
                      >
                        [x]
                      </a>
                    </td>
                    <td>
                      <div
                        className="d-inline-block text-truncate"
                        style={{ maxWidth: '25vw' }}
                        title={entry.id}
                      >
                        {entry.id}
                      </div>
                      <div className="badge text-bg-success">
                        {entry.attestationFormat}
                      </div>
                      <div className="badge text-bg-secondary">
                        {entry.attestationType}
                      </div>
                    </td>
                    <td>
                      <span
                        className="d-inline-block text-truncate"
                        style={{ maxWidth: '10rem' }}
                        title={entry.credentialPublicKey}
                      >
                        {entry.credentialPublicKey}
                      </span>
                    </td>
                    <td>
                      {entry.transports.map((transport) => (
                        <div key={transport} className="badge text-bg-primary">
                          {transport}
                        </div>
                      ))}
                    </td>
                    <td>{entry.storedSignCount}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
}
